import React from 'react';
import {Link, useParams} from "react-router-dom";
import {baseUrl} from "../../vendor/constants";
import servicePlaceholder from '../../vendor/img/service-placeholder.png'
import {useGetServiceCategoryQuery} from "../../redux/servicesApi";
import Preloader from "../Preloader/Preloader";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const UslugiChoosenCategory = () => {
    const {categoryLink} = useParams();
    const {data: category, isLoading, isError} = useGetServiceCategoryQuery(categoryLink)
    const hasCountBlock = category?.services?.some(x => x.count !== null && Number(x.count) > -1)

    if (isLoading) return <Preloader />

    if (isError) return <NotFoundPage />

    return (
        <div className="uslugiPage">
            <div className="bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/services" className="bread-crumbs-link" >Услуги</Link>
                <Link to={`/services/${category?.slug}`} className="bread-crumbs-link" >{category?.name}</Link>
            </div>
            <h2 className="uslugiPage__title">{category?.name}</h2>
            <div className="uslugiGrid">
                <ul className="uslugiGrid__container">
                    {category?.services?.map(service => {
                        const categoryImage = service.logo ? baseUrl + service.logo.thumb : servicePlaceholder
                        return (
                            <li key={service.slug} className="uslugiGrid__element">
                                {hasCountBlock && <div className="uslugiGrid__count">
                                    {service.count !== null && Number(service.count) > -1 && <>Свободных мест: {service.count}</>}
                                </div>}
                                <Link to={service.slug}>
                                    <div className="uslugiGrid__image-container">
                                        <img alt={category.name} className="uslugiGrid__image" src={categoryImage}/>
                                    </div>
                                </Link>
                                <div className="uslugiGrid__description">{service.name}</div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
};

export default UslugiChoosenCategory;
